import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import el from "element-ui/src/locale/lang/el";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: () => import(/* webpackChunkName: "layout" */ '../views/Layout.vue'),
        redirect: "/dashboard",
        children: [
            {
                path: 'dashboard',
                name: '数据大盘',
                component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
                meta: {requireAuth: true},
            },
            {
                path: 'schoolRecruit',
                name: '校园招聘',
                component: () => import(/* webpackChunkName: "schoolRecruit" */ '../views/SchoolRecruit.vue'),
                meta: {requireAuth: true},
            },
            {
                path: 'socialRecruit',
                name: '人员信息管理',
                component: () => import(/* webpackChunkName: "socialRecruit" */ '../views/SocialRecruit.vue'),
                meta: {requireAuth: true},
            },
            {
                path: 'postManage',
                name: '岗位信息管理',
                component: () => import(/* webpackChunkName: "postManage" */ '../views/PostManage.vue'),
                meta: {requireAuth: true},
            },
            {
                path: 'turnsManage',
                name: '招聘批次管理',
                component: () => import(/* webpackChunkName: "turnsManage" */ '../views/TurnsManage.vue'),
                meta: {requireAuth: true},
            },
        ],
        meta: {requireAuth: true}
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: '登录',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    },
    {
        path: '/404',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // 给面包屑使用
    localStorage.setItem("currentPathName", to.name)
    localStorage.setItem("currentPath", to.path)
    // 触发store更新
    store.commit("setPath")
    if (to.meta.requireAuth) {
        //1.如果准备跳转的目标页面需要用户认证
        if (!localStorage.getItem("user")) {
            // 1.1 如果用户信息为空
            if (to.path === "/login") {
                // 1.1.1 用户信息为空时跳转至login页面，redirect为“/”，即登录成功后跳转至首页
                next({
                    path: "/login",
                    query: {redirect: "/"}
                })
            } else {
                // 1.1.2 用户信息为空时，跳转至非login目标页面，redirect为页面本身path，即登录成功后跳转至目标页面
                next({
                    path: "/login",
                    query: {redirect: to.fullPath}  // 'to.fullPath'跳转到登录之前页面的路径
                });
            }
        } else {
            // 1.2 用户信息不为空，直接放行
            next()
        }
    } else {
        // 2. 目标页面不需要用户认证
        if (to.path === "/login") {
            // 2.1 如果目标页面为login
            if (!localStorage.getItem("user")) {
                // 2.1.1 不存在用户信息，则直接放行跳转到login页面
                next()
            } else {
                // 2.1.2 如果存在用户信息，则放行跳转到首页
                next("/")
            }
        } else {
            // 2.2 如果目标页面为非login，直接放行
            next()
        }
    }
})

export default router
