import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    currentPathName: '',
    currentPath:'',
    currentUserInfo:{
      userRoleId:'',
      userOrganizationId:'',
    }
  },
  mutations: {
    setPath (state) {
      state.currentPathName = localStorage.getItem("currentPathName")
      state.currentPath = localStorage.getItem("currentPath")
    },
    logout() {
      // 清空缓存
      localStorage.removeItem("user")
      localStorage.removeItem("menus")
      router.push("/login")
    },
    setCurrentUser(state){

    }
  }
})

export default store