import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/global.css'
import '@/assets/fonts/iconfont/iconfont.css'
import AFTableColumn from 'af-table-column'
import request from "@/utils/request";

Vue.use(ElementUI,{size:'small'})
Vue.use(AFTableColumn)
Vue.config.productionTip = false
Vue.prototype.request = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
